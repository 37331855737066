import {get} from 'lodash';

const getClosestDealer = (postcode, dealers, country) => {
  var closestDealer;
  const countryCode = country ? `${country}-` : 'AU-'
  for (var i = 0; i < dealers?.length; i++) {
    const dealer = dealers[i];
    const postCodes = get(dealer, 'acf_dealers.postcodes', []);

    for (var j = 0; j < postCodes.length; j++) {
      if (`${countryCode}${postcode}` === postCodes[j]) {
        closestDealer = dealer
        break;
      }
    }
  }

  return closestDealer
}

export default getClosestDealer