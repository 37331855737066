import React from 'react';
import PropTypes from 'prop-types';
import {Tabs, Tab} from '@material-ui/core';
import styled from 'styled-components';
import {theme} from '../../../themes/default-theme';

const TabsContainer = styled(props => {
  return <Tabs {...props} />
})`
  &:hover: {
    outline: 'none',
  }
  & .MuiTabs-indicator {
    background: none;
  }
  &.socials {
    max-width: 480px;
    margin: 52px auto;
  }
`;

const TabSelect = styled(props => {
  return <Tab {...props} classes={{selected: 'selected'}} />
})`
  & {
    font-size: 17px;
    color: ${theme.palette.gunmentalGrey};
    background : ${theme.palette.white};
    text-transform: capitalize;
    font-weight: 400;
    border: 1px solid ${theme.palette.middleGrey};
    ${theme.breakpoints.down("sm")} {
      font-size: 12px;
    }
  }
  &.selected {
    color: ${theme.palette.white};
    background-color: ${theme.palette.vividBlue};
    border: 1px solid ${theme.palette.vividBlue};
  }
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

export const TabSelection = props => {
  const [value, setValue] = React.useState(props.activeIndex);
  const {data, variant, socials} = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onChange(newValue);
  };

  return (
    <TabsContainer
      className={socials && 'socials'}
      value={value}
      onChange={handleChange}
      variant={variant}
      centered={true}
    >
      {data && data.map(function(tab, index) {
        return (
          <TabSelect
            key={tab.id || index}
            label={tab.title} 
          />
        )
      })}
    </TabsContainer>
  );
}

TabSelection.propTypes = {
  data: PropTypes.array,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  activeIndex: PropTypes.number,
  socials: PropTypes.bool
};

TabSelection.defaultProps = {
  variant: 'fullWidth',
  onChange: () => {},
  activeIndex: 0
};

export default TabSelection;