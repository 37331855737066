import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import styled from 'styled-components';
import {ReactSVG} from 'react-svg';
import {TextField} from '@material-ui/core';
import {get} from 'lodash';

import {Cta, Icon as UiIcon} from '../../ui';
import pin from '../../../assets/icons/pin-multi-coloured.svg';
import close from '../../../assets/icons/close.svg';
import {theme} from '../../../themes/default-theme';

import getClosestCoordinate from '../../../util/getClosestCoodinate';
import getClosestDealer from '../../../util/getClosestDealer';
import getClosestDealersByPostcode from '../../../util/getClosestDealersByPostcode';


const customTheme = createTheme({
  overrides: {
    MuiDrawer: {
      root: {
        top: 'auto !important',
      },
      paper: {
        boxShadow: 'none',
        background: 'transparent',
        [theme.breakpoints.up('md')]: {
          paddingBottom: 80
        }
      }
    },
    MuiBackdrop: {
      root: {
        display: 'none',
      }
    }
  }
});

const Wrapper = styled.div`
  margin: 0 auto;
  width: 1165px;
  max-width: 100%;
  background-color: ${theme.palette.charcoal};
  color: white;
  padding: 20px;
  border: 2px solid ${theme.palette.skyBlue};
  border-radius: 40px 0 40px 0;
  transition: bottom 300ms ease;

  position: fixed;
  bottom: 50px;
  z-index: 10;
  left: 0;
  right: 0;

  ${props => !props.open && `
    bottom: -100%;
  `}

  ${theme.breakpoints.up('md')} {
    padding: 50px;
  }

  ${theme.props.mobileNavDown} {
    bottom: 90px;

    ${props => !props.open && `
      bottom: -100%;
    `}

  }
`

const Container = styled.div`

  ${theme.breakpoints.up('md')} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const Icon = styled.div`
  width: 90px;
  height: 90px;
  margin-right: 15px;

  ${theme.breakpoints.down('sm')} {
    margin: 0 auto 20px;
  }

  svg {
    width: 90px;
    height: 90px;
  }
`

const Col = styled.div`
  padding: 0 10px;
`

const Title = styled.p`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 25px;
  margin-top: 0;
  ${theme.breakpoints.down('sm')} {
    text-align: center;
  }
`

const Text = styled.p`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  position: relative;
  top: 3px;
  text-align: center;

  ${theme.breakpoints.down('sm')} {
    top: 0;
  }
`

const Input = styled(TextField)`
  width: 100%;
  
  input {
    background-color: white;
    border-radius: 55px;
    line-height: 15px;
    padding: 12px 20px;
    min-width: 210px;

    ::-webkit-input-placeholder { line-height: normal; }

    ${theme.breakpoints.down('sm')} {

    }
  }

  fieldset {
    border-radius: 55px;
  }
  
`

const Submit = styled(Cta)`
  ${theme.breakpoints.down('sm')} {
    margin-top: 25px;
  }
`

const SetLocationWrapper = styled.span`
  ${props => !props.isEnabled && `
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  `}
`

const SetLocation = styled(Cta)`
  min-width: 250px;
`

const Error = styled.p`
  position: absolute;
  margin: 5px;
`

const Close = styled.div`
  position: absolute;
  top: 17px;
  right: 17px;
`


const PostcodePopup = ({
  title,
  alwaysOpen,
  dealers,
  countryCode
}) => {

  const [state, setState] = useState(false);
  const [postcode, setPostcode] = useState('');
  const [postcodeError, setPostcodeError] = useState(false);
  const [settingLocation, isSettingLocation] = useState(false);
  const [locationBtnActive, setLocationBtn] = useState(true);

  useEffect(()=> {
    const hasUserLocation = window.localStorage.userLocation ? true : false;

    const showPostcodePopup = window.localStorage.showPostcodePopup === 'false' ? false : true;

    setState(alwaysOpen ? true : (hasUserLocation ? false : showPostcodePopup));

    navigator.permissions && navigator.permissions.query({name: 'geolocation'})
    .then((PermissionStatus) => {
      if (PermissionStatus.state !== 'granted' && PermissionStatus.state !== 'prompt') {
        setLocationBtn(false);
      }
    })
  }, [])

  const handlePostcodeChange = (e) => {
    const val = e.target.value;
    if (!isNaN(val) && val.length <= 4) {
      setPostcode(val);
    }
  }

  const getCurrentLocation = () => {

    if (navigator.geolocation) {
      isSettingLocation(true);
      navigator.geolocation.getCurrentPosition(showPosition, () => {
        setLocationBtn(false);
        isSettingLocation(false);
      });
    } else {
      setLocationBtn(false);
      setState(false);
    }
  }

  const showPosition = (position) => {
    const localStorage = window.localStorage;
    const location = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    }
    const userLocation = window.localStorage.userLocation ? JSON.parse(window.localStorage.userLocation) : {}     
    localStorage.setItem('userLocation', JSON.stringify({
      ...userLocation,
      ...location
    }));

    const closest = getClosestCoordinate(location, dealers.map(item => {
      return {
        ...item,
        longitude: parseFloat(get(item, 'acf_dealers.longitude', '')),
        latitude: parseFloat(get(item, 'acf_dealers.latitude', '')),
      }
    }));

    closest && localStorage.setItem('dealer', JSON.stringify({
      ...closest
    }));

    setState(false);
    isSettingLocation(false);
    setLocationBtn(true);
  }

  const handlePostcodeKeyup = (e) => {
    if (postcode.length === 4 && e.keyCode == 13) {
      handleSubmit();
      return
    }
    if (e.keyCode === 13) {
      setPostcodeError(true)
    }
  }

  const handleClose = () => {
    setState(false);
    if (window && window.localStorage) {
      window.localStorage.setItem('showPostcodePopup', 'false')
    }
  }

  const handleSubmit = () => {
    if (postcode.length !== 4) {
      setPostcodeError(true)
    } else {
      const userLocation = window.localStorage.userLocation ? JSON.parse(window.localStorage.userLocation) : {}
      const location = {
        postcode
      }
      if (window && window.localStorage) {
        window.localStorage.setItem('userLocation', JSON.stringify({
          ...userLocation,
          ...location
        }));

        const closestDealer = getClosestDealer(postcode, dealers, countryCode);
        const closestDealerByPostcode = getClosestDealersByPostcode(postcode, dealers, 1);
        const closestDealerObj = closestDealer ? closestDealer : closestDealerByPostcode[0]
        window.localStorage.setItem('dealer', JSON.stringify({
          ...closestDealerObj,
        }));

      }
      setState(false);
    }
  }

  return (
    <ThemeProvider theme={customTheme}>
      {/* <SwipeableDrawer
        anchor='bottom'
        open={state}
        disableSwipeToOpen={true}
        onOpen={() => setState(true)}
        onClose={() => setState(false)}
      > */}
      <Wrapper open={state}>
        <Close>
          <UiIcon onClick={handleClose} skyBlue>
            <ReactSVG src={close} />
          </UiIcon>
        </Close>
        <Container>
          <Icon>
            <ReactSVG src={pin} />
          </Icon>
          <Col>
            {title && <Title>{title}</Title> }
            <SetLocationWrapper
              isEnabled={locationBtnActive}
            >
              <SetLocation
                onClick={getCurrentLocation}
                secondary>{settingLocation ? 'Setting location...' : 'Set my current location'}</SetLocation>
            </SetLocationWrapper>
            {!locationBtnActive &&
              <Error>Browser location not enabled</Error>
            }
          </Col>
          <Col>
            <Text>Or</Text>
          </Col>
          <Col>
            <ThemeProvider theme={theme}>
              <Input
                type="text"
                placeholder={'Enter your postcode'}
                value={postcode}
                onChange={(e) => handlePostcodeChange(e)}
                onKeyUp={handlePostcodeKeyup}
                error={postcodeError}
                variant="outlined"
                type="tel"
              />
            </ThemeProvider>
          </Col>
          <Col>
            <Submit onClick={handleSubmit}>Submit</Submit>
          </Col>
        </Container>
      </Wrapper>
      {/* </SwipeableDrawer> */}
    </ThemeProvider>
  )
}
  
PostcodePopup.propTypes = {
  alwaysOpen: propTypes.bool,
  title: propTypes.string,
  dealers: propTypes.array,
  countryCode: propTypes.string.isRequired
}
  
PostcodePopup.defaultProps = {
  alwaysOpen: false,
  title: null,
  dealers: null,
  countryCode: 'AU'
}
  
export default PostcodePopup