import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {Tabs, Tab} from '@material-ui/core';
import styled from 'styled-components';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import {findIndex} from 'lodash';
import {theme} from '../../../themes/default-theme';
import cube from '../../../assets/icons/cube.svg';
import TabsCurvedMobile from './Dropdown';

const TabsContainer = styled(props => {
  return <Tabs {...props} />
})`
  &:hover: {
    outline: 'none',
  }
  & .MuiTabs-indicator {
    background: none;
  }
  &.socials {
    max-width: 480px;
    margin: 52px auto;
  }

  ${theme.breakpoints.down("sm")} {
    max-height: 30px;
  }
`;

const TabSelect = styled(props => {
  return <Tab {...props} classes={{selected: 'selected'}} />
})`
  & {
    font-size: 17px;
    line-height: 23px;
    padding: 0 18px;
    color: ${theme.palette.gunmentalGrey};
    background : ${theme.palette.white};
    text-transform: capitalize;
    font-weight: 400;
    border: 1px solid ${theme.palette.middleGrey};
    min-width: 85px;
    opacity: 1;
    min-height: 50px;
    height: 50px;

    ${theme.breakpoints.down("sm")} {
      font-size: 12px;
      line-height: 16px;
      height: 30px;
      min-height: 30px;
    }

    ${props => props.background == 'dark' && `
      color: ${theme.palette.white};
      background: #292929;
      border: 1px solid #575757;
    `}
  }

  &.selected {
    color: ${theme.palette.white};
    background-color: ${theme.palette.vividBlue};
    border: 1px solid ${theme.palette.vividBlue};

    > span:first-child:before {
      background-color: white;
    }
  }
  ${props => props.countchild && `
  
    &:after {
      content: '(${props.countchild})';
      margin-left: 2px;
    }

  `}

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  ${props => props.tour == 1 && `
    padding-left: 46px !important;

    > span:first-child:before {
      content: '';
      width: 17px;
      height: 20px;
      background-color: ${theme.palette.gunmentalGrey};
      -webkit-mask-image: url(${cube});
      mask-image: url(${cube});
      mask-size: contain;
      position: absolute;
      left: 24px;
      top: 50%;
      transform: translateY(-50%);

      ${props => props.background == 'dark' && `
        background-color: ${theme.palette.white};
      `}
    }
  `}
  ${props => props.badge && `
    &:before {
      content: "${props.badge}";
      position: absolute;
      background: #00AEEF;
      color: #fff;
      line-height: 14px;
      ${theme.breakpoints.up("md")} {
        top: -9px;
        padding: 2px 4px;
        border-radius: 6px;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 700;
        min-width: 43px;
      }
      ${theme.breakpoints.down("sm")} {
        top: -12px;
        padding: 1px;
        border-radius: 4px;
        font-size: 7px;
        text-transform: uppercase;
        font-weight: 700;
        min-width: 24px;
      }
    }
  `}
`;

export const TabCurvedSelection = props => {
  const [value, setValue] = React.useState(props.activeIndex);
  const {
    data,
    variant,
    width,
    neverNative,
    activeMobileLength,
    background
  } = props;

  useEffect(() => {
    setValue(props.activeIndex);
  },[props.activeIndex]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onChange(newValue);
  };

  const mobileDropdownValue = data[value]

  return (
    <TabsContainer
      value={value}
      onChange={handleChange}
      variant={variant}
      centered={true}
    >
      {data &&
        isWidthUp('md', width) ?
          data.map(function(tab, index) {
            return (
              <TabSelect
                key={tab.id || index}
                label={tab.title}
                tour={tab.tour ? 1 : 0}
                countchild={tab.countchild && tab.countchild}
                background={background}
                badge={tab.badge && tab.badge}
              />
            )
          })
        :
          <>
            {data.slice(0, activeMobileLength).map(function(tab, index) {
              return (
                <TabSelect
                  key={tab.id || index}
                  label={tab.title}
                  tour={tab.tour ? 1 : 0}
                  background={background}
                  badge={tab.badge && tab.badge}
                  className={value === index ? 'selected' : ''}
                  onChange={(e) => handleChange(e,index)}
                />
              )
            })}
            {activeMobileLength < data.length &&
              <TabsCurvedMobile
                className={value >= activeMobileLength ? 'selected' : ''}
                label={value >= activeMobileLength ? mobileDropdownValue.title : `+${data.slice(activeMobileLength).length} more`}
                active={value >= activeMobileLength ? true : false}
                options={data.slice(activeMobileLength).map(item => {
                  return {
                    text: item.title,
                    value: item.title,
                    badge: item.badge && item.badge
                  }
                })}
                neverNative={neverNative}
                onChange={(e) => {
                  handleChange(e, data.findIndex(i => i.title === e.value))
                }}
                background={background}
              />
            }
          </>
      }
    </TabsContainer>
  );
}

TabCurvedSelection.propTypes = {
  data: PropTypes.array,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  active: PropTypes.string,
  activeIndex: PropTypes.number,
  neverNative: PropTypes.bool,
  activeMobileLength: PropTypes.number,
  background: PropTypes.string
};

TabCurvedSelection.defaultProps = {
  variant: 'fullWidth',
  onChange: () => {},
  activeIndex: 0,
  active: null,
  neverNative: false,
  activeMobileLength: 2,
  background: null
};

export default withWidth()(TabCurvedSelection);