import React, {useState} from 'react';
import styled from 'styled-components';
import {theme} from '../../../themes/default-theme';
import {EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon} from "react-share";
import Typography from '@material-ui/core/Typography';
import Links from '../Links';
import {ReactSVG} from 'react-svg';
import Modal from '../Modal';

import share from '../../../assets/icons/upload-share.svg';
import Topo from '../../../assets/topo-block-blue.png';

const CaptionContainer = styled.div`
  width: 450px;
  height: 260px;
  background: white;
  margin: 0 auto;
  position: relative;
  border-bottom-right-radius: 80px;

  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
  }

  &:before {
    content: '';
    background: url(${Topo});
    background-size: cover;
    width: 65px;
    height: 100%;
    display: block;
  }
`

const ShareHeading = styled.div`
  font-size: 14px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  color: ${theme.palette.charcoal};
  letter-spacing: 1.4px;
`

const StyledLinks = styled.div`
  width: 46px;
  height: 46px;
  background-image: url(${share});
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
`

const Email = styled(EmailIcon)`
  border-radius: 80px;
  
  & rect {
    fill: ${theme.palette.vividBlue};
  }
`

const Facebook = styled(FacebookIcon)`
  border-radius: 80px;

  & rect {
    fill: ${theme.palette.vividBlue};
  }
`

const Twitter = styled(TwitterIcon)`
  border-radius: 80px;

  & rect {
    fill: ${theme.palette.vividBlue};
  }
`

export const SocialShare = props => {
  const [sharePopup, setSharePopup] = React.useState(false);

  const handleChange = () => {
    setSharePopup(!sharePopup);
    !sharePopup && props.onLaunch();
  };

  const handleClick = (type) => {

  }

  return (
    <>
      <Typography>
        <StyledLinks onClick={handleChange} />
      </Typography>
      <Modal
        isOpen={sharePopup}
        onClose={() => setSharePopup(false)}
        innerClassname={'share-container'}
      >
        <CaptionContainer>
          <div>
            <ShareHeading>Share this page on:</ShareHeading>
            <span onClick={() => props.onShareClick('Email')}>
              <EmailShareButton url={global.location}>
                <Email size={38}/>
              </EmailShareButton>
            </span>
            <FacebookShareButton url={global.location} onClick={() => props.onShareClick('Facebook')}>
              <Facebook size={38} />
            </FacebookShareButton>
            <TwitterShareButton url={global.location} onClick={() => props.onShareClick('Twitter')}>
              <Twitter size={38} />
            </TwitterShareButton>
          </div>
        </CaptionContainer>
      </Modal>
    </>
  );
}

SocialShare.defaultProps = {
  onShareClick: () => {},
  onLaunch: () => {}
}

export default SocialShare;