import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import styled from 'styled-components';

import {Cta, Icon as UiIcon} from '../../ui';
import close from '../../../assets/icons/close.svg';
import {theme} from '../../../themes/default-theme';
import Text from '../../typography';

const customTheme = createTheme({
  overrides: {
    MuiDrawer: {
      root: {
        top: 'auto !important',
      },
      paper: {
        boxShadow: 'none',
        background: 'transparent',
        [theme.breakpoints.down('sm')]: {
          
        }
      }
    },
    MuiBackdrop: {
      root: {
        display: 'none',
      }
    }
  }
});

const Wrapper = styled.div`
  width: 100%;
  background: ${theme.palette.skyGradient};
  color: white;
  padding: 20px 20px 105px;
  position: fixed;
  left: 0;
  z-index: 9;
  transition: bottom 300ms ease;
  bottom: -100%;

  ${props => props.open && `
    bottom: 0;
  `}

  ${theme.props.desktopNavUp} {
    padding: 28px;
  }
`

const Container = styled.div`
  text-align: center;

  ${theme.props.desktopNavUp} {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
`

const ContentItem = styled.div`
  margin: 15px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${theme.props.desktopNavUp} {
    margin: 0;
    padding: 0 15px;
  }
`

const BannerPopup = ({
  text,
  alwaysOpen,
  open,
  cta,
  onClick
}) => {

  const [state, setState] = useState(false);

  useEffect(() => {
    alwaysOpen && setState(true);
  }, [])

  useEffect(() => {
    if (open !== null) {
     setState(open);
     // Add padding to body
    }
  }, [open])

  return (
    <Wrapper open={state}>
      <Container>
        <ContentItem>
          <Text variant="subtitle1" align="center">{text}</Text>
        </ContentItem>
        {cta &&
          <ContentItem>
            <Cta href={cta.href} onClick={onClick} inline disabled={cta.disabled || false}>{cta.title}</Cta>
          </ContentItem>
        }
      </Container>
    </Wrapper>
  )
}
  
BannerPopup.propTypes = {
  text: propTypes.string,
  cta: propTypes.object,
  alwaysOpen: propTypes.bool,
  open: propTypes.bool,
  onClick: propTypes.func
}
  
BannerPopup.defaultProps = {
  text: null,
  cta: null,
  open: null,
  onClick: () => {}
}
  
export default BannerPopup