
import {get, findIndex} from 'lodash';

const getClosestDealersByPostcode = (postcode, dealers, topDealerCount) => {
  let closestDealers = [];
  const postcodeInt = parseInt(postcode);
  const count = topDealerCount > dealers?.length ? dealers?.length : topDealerCount || 1

  for (var i = 0; i < count; i++) {

    const filteredDealers = dealers && dealers.length > 0 && dealers.filter(item => {
      if (findIndex(closestDealers, {title: item.title}) === -1) {
        return true
      }
    })

    var closest = filteredDealers && filteredDealers.length > 0 && filteredDealers.reduce((prev, curr) => {

      const currPostVal = get(curr, 'acf_dealers.postcode', null);
      const currPost = parseInt(currPostVal);
      const prevPostVal = get(prev, 'acf_dealers.postcode', '0000');
      const prevPost = parseInt(prevPostVal);

      return (Math.abs(currPost - postcodeInt) < Math.abs(prevPost - postcodeInt) ? curr : prev);
    });

    closestDealers.push(closest)
  }

  return closestDealers
}

export default getClosestDealersByPostcode;