import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Grid, Box} from '@material-ui/core';
import {get} from 'lodash';

import {theme} from '../../../themes/default-theme';
import TabsMobileDropdown from '../TabsMobileDropdown';
import Typography from '../../typography';

import Accordion from '../Accordion';
import queryString from 'query-string';

const TabsContainer = styled.div`
  margin-bottom: 60px;

  ${theme.breakpoints.down('sm')} {
    margin-bottom: 10px;
  }
`

const Container = styled.div`
  max-width: 780px;
  margin: 0 auto;
`

const Title = styled(Typography)`
  margin-bottom: 15px;
  max-width: 812px;
  padding-left: 16px;
  padding-right: 16px;
`

const TabbedAccordion = (props) => {

  const {
    items,
    hTag
  } = props

  const initialActiveTab = get(items, '[0].title', null);
  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const [activeAccordion, setActiveAccordion] = useState(null);

  useEffect(() => {
    let params = queryString.parse(location.search);
  },[]);

  useEffect(() => {
    // console.log('activeTab', activeTab);
    // console.log('activeAccordion', activeAccordion);
    // @TODO - add url functionality
  },[activeTab, activeAccordion]);

  const updateTab = (e) => {
    const item = e.text ? e.text : e;
    setActiveTab(item)
    item !== activeTab && setActiveAccordion(null);
  }

  const handleAccordionChange = (e) => {
    setActiveAccordion(e);
  }

  return (
    items ?
      <> 
      <Grid container className={'hasPadding'} spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'center'}>
        <Grid item xs={12}>

          {items.length === 1 && props.showTitle && activeTab && <Title variant={'h4'} element={hTag ? hTag : 'h4'} colour="charcoal" uppercase={true} align={'center'}>{activeTab}</Title>}

          <Box px={2}>
            <Container>

              {items.length > 1 &&
                <TabsContainer>
                  <TabsMobileDropdown
                    active={activeTab}
                    items={items.map(item => {return {text: item.title}})}
                    variant="inline"
                    tabGap={false}
                    onChange={(e) => updateTab(e)}
                  />
                </TabsContainer>
              }

              {items.map((item, index) => {
                return (
                  activeTab === item.title ?
                  <Accordion
                    key={item.title || index}
                    data={item.data}
                    onChange={handleAccordionChange}
                  /> : null
                )
              })}
            </Container>
          </Box>
        </Grid>
      </Grid>
    </>
    : null
  )
}

TabbedAccordion.propTypes = {
  items: propTypes.array.isRequired,
  showTitle: propTypes.bool
}

TabbedAccordion.defaultProps = {
  items: [],
  showTitle: false
}

export default TabbedAccordion