import React, {useRef, useState, useEffect} from 'react';
import dynamic from 'next/dynamic'
import propTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
// import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";

// Note: If not loaded dynamically it will break the build preview
const Slider = dynamic(() => import('react-slick'), {
  ssr: false
});

import {Icon} from '../../ui';
import chevronLeft from '../../../assets/icons/chevron-left.svg';
import chevronRight from '../../../assets/icons/chevron-right.svg';

const PrevArrow = ({currentSlide, slideCount, children, ...props}) => {
  return (
    <Icon grey {...props}>
      <ReactSVG src={chevronLeft} />
    </Icon>
  )
}

const NextArrow = ({currentSlide, slideCount, children, ...props}) => {
  return (
    <Icon grey {...props}>
      <ReactSVG src={chevronRight} />
    </Icon>
  )
}

const SlickSlider = ({
  settings,
  children,
  forwardedRef,
  arrowProps,
  onInit
}) => {
  var slickSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    prevArrow: <PrevArrow {...arrowProps}/>,
    nextArrow: <NextArrow  {...arrowProps}/>,
    ...settings
  };
  const backupRef = useRef(null);
  // const [isClient, setClient] = useState(false);

  // useEffect(() => {
  //   setClient(true)
  // }, [])

  const ref = forwardedRef ? forwardedRef : backupRef;

  return (
    <Slider 
      // key={isClient ? 'client' : 'server'} 
      {...slickSettings}
      onInit={onInit}
      ref={ref}>
      {children}
    </Slider>
  )
}
  
SlickSlider.propTypes = {
  settings: propTypes.object,
  forwardedRef: propTypes.object,
  arrowProps: propTypes.object,
  onInit: propTypes.func,
}
  
SlickSlider.defaultProps = {
  settings: {},
  forwardedRef: null,
  arrowProps: {
    grey: true
  },
  onInit: () => {}
}
  
export default SlickSlider