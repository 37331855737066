import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-size: 10px;
  p {
    font-size: 10px;
  }
`

const Disclaimer = ({children}) =>(
  <Container>{children}</Container>
)

export default Disclaimer;