import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types'
import styled from 'styled-components';
import { theme } from '../../../themes/default-theme';
import { Grid, Box } from '@material-ui/core';
import Text from '../../typography';

const ScriptInnerContainer = styled.div`
    > .MuiTypography-h4,
    > h4 {
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 8px;
        color: ${theme.palette.charcoal};
        ${theme.breakpoints.up('md')} {
            margin-bottom: 50px;
            font-size: 28px;
        }
        ${theme.breakpoints.down('sm')} {
            margin-bottom: 20px;
            font-size: 16px;
        }
    }
`

const Scripts = ({
    html,
    title,
    hTag
}) => {

    const [headScripts, setHeadScripts] = useState([]);
    const [scriptsContent, setScriptsContent] = useState(html);

    const detachScript = (content) => {
        let scripts = [];
        var temp = document.createElement('div');
        temp.innerHTML = content;

        var tempScripts = temp.getElementsByTagName("customscript");
        var tempScriptsArr = Array.prototype.slice.call(tempScripts);
        tempScriptsArr.forEach(item => {

            scripts.push({
                src: item.getAttribute('src'),
                js: item.innerHTML
            })
        })
        return scripts
    }

    const loadScriptSrc = (src) => {
        var tag = document.createElement('script');
        tag.async = true;
        tag.src = src;
        var form = document.querySelector('[class^="Scripts__ScriptInnerContainer"] form');
        typeof form != 'undefined' && form !== null && form.appendChild(tag);
    }

    const loadScriptContent = (content) => {
        var tag = document.createElement('script');
        tag.async = true;
        tag.innerHTML = content;
        var form = document.querySelector('[class^="Scripts__ScriptInnerContainer"] form');
        typeof form != 'undefined' && form !== null && form.appendChild(tag);
    }

    function afterCharacter(string, char) {
        return string.slice(html.indexOf(char) + 14);
      }

    useEffect(() => {
        if (html) {
            setScriptsContent(html.replace(/customscript/g, 'script'))
        }
    }, [html])

    useEffect(() => {
        var allScripts = detachScript(scriptsContent);
        setHeadScripts(allScripts);
        headScripts.map((item, index) => {
            return (item.src && item.src.length > 0) ?

                loadScriptSrc(item.src)
                :
                index != 0 &&
                <script key={item.key || index} dangerouslySetInnerHTML={{ __html: loadScriptContent(item.js) }} />

        })
        // prevent issue with first script
        loadScriptContent(html && afterCharacter(html, '<customscript>').split('</customscript')[0])
    }, [scriptsContent])

    return (
        <Grid item xs={12}>
            <Box px={2}>
                <ScriptInnerContainer>
                    {title &&
                        <Text variant="h4" element={hTag ? hTag : 'h4'}>{title}</Text>
                    }
                    <div dangerouslySetInnerHTML={{ __html: html && scriptsContent }} />

                </ScriptInnerContainer>
            </Box>
        </Grid>
    )
};


Scripts.propTypes = {
    html: propTypes.string,
    title: propTypes.string
}

Scripts.defaultProps = {
    html: null,
    title: null
}



export default Scripts