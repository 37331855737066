import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Grid, Box} from '@material-ui/core';

import Slider from '../../multimedia/Slider';
import IconText from '../IconText';
import {theme} from '../../../themes/default-theme';
import Text from '../../typography';

const Container = styled.div`
  position: relative;

  ${props => props.length > 1 && `
    padding-bottom: 50px;
  `}

  ${theme.breakpoints.up("md")} {
    ${props => props.length > 3 ? `
      padding-bottom: 60px;
    ` : `
      padding-bottom: 0;
    `}
  }

  .slick-next,
  .slick-prev {
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    margin: auto;

    ${theme.breakpoints.up("md")} {
      bottom: -60px;
    }
  }

  .slick-prev {
    transform: translateX(-20px);

    ${theme.breakpoints.up("md")} {
      transform: translateX(-30px);
    }

  }

  .slick-next {
    transform: translateX(20px);

    ${theme.breakpoints.up("md")} {
      transform: translateX(30px);
    }
  }

`

const TextContainer = styled.div`
  margin-bottom: 20px;
  ${theme.breakpoints.up("md")} {
    margin-bottom: 60px;
  }
`

const IconTextCarousel = ({
  heading,
  items,
  settings,
  hTag
}) => {

  const slickSettings = {
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    fade: false,
    slide: true,
    centerMode: false,
    variableWidth: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: theme.breakpoints.width('sm'),
        settings: {
          slidesToShow: 1
        }
      }
    ],
    ...settings,
  }

  return (
    <Container length={items.length}>
      {heading &&
        <Grid container className={'hasPadding'} spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'center'}>
          <Grid item xs={12}>
            <Box px={2}>
              <TextContainer>
                <Text variant="h2" element={hTag ? hTag : 'h2'} colour="charcoal" uppercase={true} align="center">{heading}</Text>
              </TextContainer>
            </Box>
          </Grid>
        </Grid>
      }
      <Grid container spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'center'}>
        <Grid item xs={12}>
          <Slider settings={slickSettings} arrowProps={{
            skyBlue: true,
            grey: false
          }}>
            {items.map((item, index) => (
              <IconText 
                key={item.id || index}
                {...item}
              />
            ))}
          </Slider>
        </Grid>
      </Grid>
    </Container>
  )
}
  
IconTextCarousel.propTypes = {
  heading: propTypes.string,
  items: propTypes.array.isRequired,
  settings: propTypes.object,
}
  
IconTextCarousel.defaultProps = {
  heading: null,
  items: [],
  settings: {}
}
  
export default IconTextCarousel
