import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {theme} from '../../../themes/default-theme';
import {get} from 'lodash';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Table border top for firefox bug
const TableContainerStyle =  styled(props => {
  return <TableContainer {...props} borderRadius={undefined} />
})`
  ${props => props.borderRadius && `
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  `}

  table {
    border-top: 1px solid transparent; 
  }
`;

const TableHeadStyle =  styled(props => {
  return <TableHead {...props} />
})`
  background: ${theme.palette.skyGradient};
  .MuiTableCell-root {
    font-size: 14px;
    font-weight: 900;
    color: ${theme.palette.white};
  }
`;
const TableBodyStyle = styled(props => {
  return <TableBody {...props} sideBorder={undefined} />
})`
  .MuiTableCell-root {
    font-size: 16px;
    color: ${theme.palette.gunmentalGrey};
  }
  .MuiTableCell-body {
    border: 1px solid #DCDCDC;
  }
  .MuiTableRow-root:nth-child(odd) {
    background: ${theme.palette.lightGrey};
  }

  ${props => props.sideBorder === false && `
    tr:last-child td {
      border-bottom: 0;
    }
    tr td:first-child {
      border-left: 0;
    }
    tr td:last-child {
      border-right: 0;
    }
  `}

`;

export const TableOutput = props => {
  const {header, row, sideBorder} = props;

  return (
    <TableContainerStyle borderRadius={sideBorder}>
      <Table aria-label="Table">
        <TableHeadStyle>
          <TableRow>
            {header && header.map(function(heading, index) {
              return (
                <TableCell key={index}>{heading.title}</TableCell>
              )
            })}
          </TableRow>
        </TableHeadStyle>
        <TableBodyStyle sideBorder={sideBorder}>
          {row && row.filter(item => {
              const itemVal = get(item, 'rowContent[1].title', '').toString();
              return (itemVal && (itemVal.toLowerCase() !== 'n/a' && itemVal.toLowerCase() !== 'no'))
            }).map(function(row, index) {
            return (
              <TableRow key={index}>
                {row.rowContent.map(function(rowContent, index) {
                  return (
                    <TableCell key={index}>{rowContent.title && rowContent.title}</TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBodyStyle>
      </Table>
    </TableContainerStyle>
  );
}

TableOutput.propTypes = {
  header: PropTypes.array,
  row: PropTypes.array,
  sideBorder: PropTypes.bool
};

TableOutput.defaultProps = {
  header: [],
  row: [],
  sideBorder: true
};

export default TableOutput;