import React, {useRef, useEffect, useState} from 'react';
import Link from 'next/link';
import ReactDOM from 'react-dom';
import Router from 'next/router';

const NextLink = (props) => {
  const {
    children
  } = props;

  // const prefetch = props && props.href && props.href.length > 0 && props.href.indexOf('#') !== 0 && props.href.indexOf('http') == -1 ? true : undefined
  const prefetch = (!props.as || process.env.NODE_ENV === 'development') ? false : undefined;
  const development = process.env.NODE_ENV === 'development';
  // const prefetch = !props.as ? false : undefined;
  const regularLink = false

  const linkProps = !props.as && props.href ? {
    ...props,
    as:props.href
  } : props

  const htmlLinkProps = {
    ...props,
    href: props.as || props.href
  }
  
  return (
      (props.href && !process.env.DEALER_SITE) && (regularLink !== true) ?
      // prefetch && !development ?
      <Link
        {...linkProps}
        prefetch={prefetch}
        >
        {children}
      </Link>
      :
      // Use regular link in dev mode to speed up
      <RegularLink {...htmlLinkProps}>{children}</RegularLink>
      // : <>{children}</>
    
  )
}

const RegularLink = (props) => {
  const link = useRef(null);

  const {
    children
  } = props

  useEffect(() => {
    const linkAtt = link.current ? ReactDOM.findDOMNode(link.current).childNodes[0] : null
    if (linkAtt && props.href && ReactDOM.findDOMNode(link.current).childNodes[0].setAttribute) {
      ReactDOM.findDOMNode(link.current).childNodes[0].setAttribute('href', props.href);
    }
  }, []);

  // const handleClick = (e) => {
  //   e.preventDefault();
  //   Router.push(props.href);
  //   Router.push(props.href, undefined, { shallow: true })
  // }

  return (
    <span ref={link}>
      {children}
    </span>
  )
}


export default NextLink