import React, {useEffect, useState} from 'react';
import {get} from 'lodash';
import propTypes from 'prop-types';
import styled from 'styled-components';
import renderHTML from 'react-render-html';
import { Select, MenuItem, InputLabel, FormControl, ButtonBase } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import {theme} from '../../../themes/default-theme';
import {ReactSVG} from 'react-svg';
import chevronDown from '../../../assets/icons/chevron-down.svg';
import mobileCheck from '../../../util/mobileCheck';
import Router from 'next/router';

export const dropdownTheme = createTheme({
  ...theme,
})

const liteTheme = createTheme({
  ...theme,
})

const ChevronIcon = styled(ButtonBase)`
  pointer-events: none;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-60%);
  transition: transform 300ms ease-in-out;
  margin-top: 1px;

  ${theme.breakpoints.up('md')} {
    top: 10px;
  }

  svg {
    [stroke-width] {
      stroke: ${theme.palette.gunmentalGrey};
    }
  }

  ${props => props.background == 'dark' && `
    svg {
      [stroke-width] {
        stroke: ${theme.palette.white};
      }
    }
  `}
`

const BadgeItem = styled.div`
  position: absolute;
  background: #00AEEF;
  color: #fff;
  line-height: 14px;
  top: -7px;
  padding: 1px;
  border-radius: 4px;
  font-size: 7px;
  text-transform: uppercase;
  font-weight: 700;
  min-width: 24px;
  text-align: center;
}
`

const Dropdown = ({
  label,
  options,
  onChange,
  onUpdate,
  alwaysNative,
  neverNative,
  defaultValue,
  value,
  formVal,
  error,
  showComplete,
  disabled,
  link,
  background,
  open,
  active
}) => {

  dropdownTheme.overrides = {
    ...theme.overrides,
    Complete: {
      'fieldset': {
        background: 'red'
      }
    },
    MuiOutlinedInput: {
      root: {
        minWidth: 100,
        borderRadius: 0,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        border: `1px solid ${theme.palette.middleGrey}`,
        color: theme.palette.white,
        borderLeft: 0,
      },
      notchedOutline: {
        border: 0,
      }
    },
    MuiInputLabel: {
      root: {
        "&$outlined": {
          transform: 'translate(0, 18px)',
          paddingLeft: 20,
          paddingRight: 40,
          position: 'relative',
          marginTop: '-6px',
          color: theme.palette.gunmentalGrey,
          fontSize: 12,
          lineHeight: '6px',
          '&$shrink': {
            transform: 'translateY(-50%)'
          },
          [theme.breakpoints.up('md')]: {
            fontSize: 16,
            marginTop: 0
          }
        }
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: theme.palette.darkGrey,
        },
        '&$filled': {
          display: 'none'
        }
      }
    },
    MuiList: {
      root: {
        "&$padding": {
          padding: 0,
        }
      } 
    },
    MuiPaper: {
      root: {
        "&$elevation8": {
          boxShadow: 'none',
          overflow: 'visible',
          marginTop: 20,
          backgroundColor: 'white',
          border: `1px solid ${theme.palette.middleGrey}`,
        }
      }
    },
    MuiSelect: {
      root: {
        
        maxHeight: '30px',
        lineHeight: '16px',
        "&$select:focus": {
          backgroundColor: 'transparent'
        },
        [theme.breakpoints.up('md')]: {
          padding: '14.5px 0px',
        },
        [theme.breakpoints.down('sm')]: {
          height: '28px !important',
          padding: 0
        }
      },
      disabled: {
        opacity: 0.5
      }
    },
    MuiListItem: {
      root: {
        fontSize: '16px',
        "&$selected, &$selected:hover": {
          backgroundColor: theme.palette.vividBlue,
          color: 'white'
        }
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '16px',
        overflow: 'visible',
        padding: '12.5px 12.5px',
        textTransform: 'capitalize',
        "&:not(:last-child)": {
          borderBottom: `1px solid ${theme.palette.middleGrey}`,
        },
      }
    }
  }
  
  liteTheme.overrides = {
    ...dropdownTheme.overrides,
    MuiOutlinedInput: {
      root: {
        minWidth: 100,
        borderRadius: 0,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        border: `1px solid #575757`,
        borderLeft: 0,
        color: theme.palette.white,
        background: '#292929',
      },
      notchedOutline: {
        border: 0,
      }
    },
    MuiInputLabel: {
      root: {
        "&$outlined": {
          color: theme.palette.white,
          fontSize: 12,
          lineHeight: '6px',
          transform: 'translate(0, 18px)',
          paddingLeft: 20,
          paddingRight: 40,
          position: 'relative',
          marginTop: '-6px',
          [theme.breakpoints.up('md')]: {
            fontSize: 16,
            marginTop: 0
          }
        }
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: theme.palette.white,
        },
        '&$filled': {
          display: 'none'
        }
      }
    },
    MuiPaper: {
      root: {
        "&$elevation8": {
          boxShadow: 'none',
          marginTop: 20,
          backgroundColor: 'white',
          border: `1px solid #575757`,
        }
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '16px',
        padding: '12.5px 12.5px',
        background: '#292929 !important',
        color: 'white',
        "&:hover, &$active, &$focused": {
          background: '#292929!important',
        },
        "&:not(:last-child)": {
          borderBottom: `1px solid #575757`
        }
      }
    }
  }

  // transform: translate(0, 18px);
  // padding-left: 20px;
  // padding-right: 40px;
  // position: relative;
  // margin-top: -6px;
  
  console.log('dropdownThemedropdownTheme', dropdownTheme);
  
  const [isNative, setNative] = useState(true);
  const [isOpen, setOpen] = useState(open);

  useEffect(() => {
    setOpen(open);
  }, [open])

  const handleChange = (value) => {
    if (link) {
      Router.push(value);
      return;
    }
    const item = options.find(item => item.value.toString() === value.toString());
    onChange(item)
    onUpdate(item) // for react hook forms
  }

  useEffect(() => {
    if (neverNative) {
      setNative(false);
    } else {
      const native = alwaysNative ? true : mobileCheck();
      setNative(native)
    }
  },[])


  const disableScroll = (e) => {
    if (document.body.classList.contains('overflow-scroll-bar')) {
      e.preventDefault();
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-scroll-bar')
    } else {
      document.body.classList.remove('overflow-scroll-bar')
    }
  }, [isOpen])

  useEffect(() => {
    window.addEventListener("wheel",  (e) => disableScroll(e), {passive: false});
    return window.removeEventListener("wheel", disableScroll);
  }, [])

  const val = typeof(value) === 'object' ? get(value, 'value', undefined) : value;

  return (
    <ThemeProvider theme={background === 'dark' ? liteTheme : dropdownTheme}>
      <FormControl variant="outlined" classes={{ root: showComplete && !error ? dropdownTheme.Complete : ''}}>
        {!isNative && !formVal &&
        <InputLabel 
          disableAnimation={true}
          focused={false}
          shrink={false}
          disabled={disabled}
          >{label}</InputLabel>
        }
        <Select
          onChange={(e) => {handleChange(e.target.value)}}
          native={isNative}
          value={formVal ? formVal.value : val || ''}
          defaultValue={defaultValue || undefined}
          error={error}
          disabled={disabled}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          IconComponent={() => (
            <ChevronIcon background={background}>
              <ReactSVG src={chevronDown} />
            </ChevronIcon>
          )}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center"
            },
            getContentAnchorEl: null
          }}
        >
          {isNative && !defaultValue && label && <option value="">{label}</option> }
          {options.map(item => (
            isNative ? 
              <option key={item.id || item.value} value={item.value}>{renderHTML(item.text)}</option>
              :
              <MenuItem key={item.id || item.value} value={item.value}>
                  {
                    item.badge && <BadgeItem>{item.badge}</BadgeItem>
                  }
                  {renderHTML(item.text)}
              </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  )
}
  
Dropdown.propTypes = {
  label: propTypes.string,
  options: propTypes.array.isRequired,
  onChange: propTypes.func,
  onUpdate: propTypes.func,
  alwaysNative: propTypes.bool,
  formVal: propTypes.object,
  defaultValue: propTypes.string,
  value: propTypes.any,
  error: propTypes.bool,
  showComplete: propTypes.bool,
  disabled: propTypes.bool,
  link:  propTypes.bool,
  neverNative: propTypes.bool,
  open: propTypes.bool,
  background: propTypes.string,
  active: propTypes.bool
}
  
Dropdown.defaultProps = {
  label: 'Select',
  onChange: () => {},
  alwaysNative: false,
  formVal: undefined,
  defaultValue: undefined,
  value: undefined,
  error: false,
  showComplete: false,
  neverNative: false,
  onUpdate: () => {},
  disabled: false,
  link: false,
  open: false,
  background: null,
  active: false
}
  
export default Dropdown