import React from 'react';
import propTypes from 'prop-types';
import ReactModal from 'react-modal';
import {makeStyles} from '@material-ui/core/styles';
import styled from 'styled-components';
import {Fade, SwipeableDrawer} from '@material-ui/core';
import clsx from 'clsx';

import {theme} from '../../../themes/default-theme';
import Icon from '../Icon';
import closeIcon from '../../../assets/icons/close.svg';
import closeIconThick from '../../../assets/icons/close-thick.svg';
import {ReactSVG} from 'react-svg';

const SwipeAway = styled(SwipeableDrawer)`
  .MuiPaper-root {
    box-shadow: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow: auto;
    position: fixed;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  & .share-container {
    
    margin: 0 auto;
    position: relative;

    & div:nth-child(2) {
      top: 15px;
      right: 15px;
    }

    & button {
      margin: 10px;
    }
  }
`

const SwipeAwayContainer = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  font-size: 0;
  text-align: center;
  overflow: auto;
  padding: 16px;

  &:before {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  ${theme.props.mobileNavDown} {
    padding: 50px 0 140px;
  }
`

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '100%',
    outline: 'none',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    overflow: 'auto',
    zIndex: 1000,
    fontSize: 0,
    textAlign: 'center',
    overflow: 'auto',
    // backgroundColor: 'rgba(0,0,0,0.5)',
    padding: '16px',

    '&:before' : {
      content: "''",
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '100%',
    },
  
    '&.ReactModal__Overlay': {
      opacity: 0,
      transition: 'opacity 300ms ease-in-out',
      transitionDelay: 300
    },

    '&.ReactModal__Overlay--after-open': {
      opacity: 1,
    },

    '&.ReactModal__Overlay--before-close': {
      opacity: 0,
    },

  },
  bgClose: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000
  },
  content: {
    position: 'relative',
    zIndex: 1001,
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '100%',
  },
  inner: {
    display: 'inline-block',
    width: '100%',
    maxWidth: '1066px',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingRight: 66,
    }
  },
  close: {
    position: 'absolute',
    bottom: '-35px',
    left: '16px',

    [theme.breakpoints.down('sm')]: {
      width: '30px',
      height: '30px',
      left: '50%',
      transform: 'translateX(-24px)'
    },

    [theme.breakpoints.up('md')]: {
      top: 0,
      bottom: 'auto',
      left: 'auto',
      right: 0,
    }
  },
  BackdropProps: {
    backgroundColor: 'rgba(46, 50, 56, 0.8)',
    filter: 'blur(6.5px)'
  }
});
  
const Modal = (props) => {
  const classes = useStyles();

  // useEffect(() => {
  //   const onEscape = () => {
  //     if(event.keyCode === 27) {
  //       props.onClose();
  //     }
  //   }
  //   document.addEventListener("keydown", onEscape, false);
  //   return () => {
  //     document.removeEventListener("keydown", onEscape, false);
  //   };
  // });

  const handleClose = () => {
    props.onClose();
  }

  return (

    <ReactModal
      shouldFocusAfterRender={false}
      className={props.rootClassname || classes.root}
      overlayClassName={clsx(classes.overlay, props.overlayClassname)}
      {...props}
      ariaHideApp={false}
      closeTimeoutMS={300}
    >
      <SwipeAway
        anchor="top"
        open={props.isOpen}
        onClose={props.onClose}
        onOpen={() => {}}
        ModalProps={{
          disableScrollLock: true,
          BackdropProps:{
            classes:{
              root: props.backdropBackground ? classes.BackdropProps : ''
            }
          }
        }}
      >
        <SwipeAwayContainer>
          <div className={props.bgCloseClassname || classes.bgClose} onClick={handleClose}/>
          <div className={props.contentClassname || classes.content} onClick={(e) => {
            (e.target.classList && e.target.classList.value.indexOf(classes.content) !== -1) && handleClose()
          }}>
            <div className={props.innerClassname || classes.inner} onClick={(e) => {
              (e.target.classList && e.target.classList.value.indexOf(classes.inner) !== -1) && handleClose()
            }}>
              {props.fadeIn ?
                <Fade in={true}>
                  {props.children}
                </Fade>
              : props.children
              }
              <div className={props.closeClassname || classes.close}>
                {props.iconVividBlue ?
                  <Icon onClick={handleClose} vividBlue>
                    <ReactSVG src={props.thickCloseIcon ? closeIconThick : closeIcon} />
                  </Icon>
                :
                  <Icon onClick={handleClose} skyBlue>
                    <ReactSVG src={props.thickCloseIcon ? closeIconThick : closeIcon} />
                  </Icon>
                }
              </div>
            </div>
          </div>
        </SwipeAwayContainer>
      </SwipeAway>

    </ReactModal>
  )
}

Modal.propTypes = {
  rootClassname: propTypes.string,
  overlayClassname: propTypes.string,
  bgCloseClassname: propTypes.string,
  contentClassname: propTypes.string,
  innerClassname: propTypes.string,
  closeClassname: propTypes.string,
  modalProps: propTypes.object,
  backdropBackground: propTypes.string,
  iconVividBlue: propTypes.bool,
  thickCloseIcon: propTypes.bool,
  fadeIn: propTypes.bool
}

Modal.defaultTypes = {
  rootClassname: null,
  overlayClassname: null,
  bgCloseClassname: null,
  contentClassname: null,
  innerClassname: null,
  closeClassname: null,
  modalProps: {},
  backdropBackground: null,
  iconVividBlue: null,
  thickCloseIcon: false,
  fadeIn: false
}
  
export default Modal