import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from "styled-components";
import {theme} from '../../../themes/default-theme';
import Link from '../../navigation/NextLink';
import { ReactSVG } from 'react-svg';
import produce from 'immer';

import chevronRight from '../../../assets/icons/chevron-right-white.svg';

const LinksContainer = styled(({ secondary, save, stroke, white, hasA, children, ...props }) => {

  // probably a better way to do this
  const linkProps = produce(props, draftProps => {
    delete draftProps.svg;
    delete draftProps.className;
    delete draftProps.onClick;
    delete draftProps.hasA
  });

  const aProps = produce(props, draftProps => {
    delete draftProps.svg
  });

  return (
    props.regularLink ?
      <a {...aProps}>
        {children}
      </a>
    :
    <Link {...linkProps}>
      {!hasA ?
        <a {...aProps}>
          {children}
        </a>
      : {children}
      }
    </Link>
  )
})`

  & {
    font-size: 17px;
    color: ${theme.palette.vividBlue};
    text-decoration: none;
  }
  & .svg-container {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    border-radius: 50%;
    background: ${theme.palette.vividBlue};
    vertical-align: middle;
    svg {
      margin: 0 auto;
      width: 100%;
      height: 12px;
    }
  }
  &:hover {
    text-decoration: none;
    color: #0C5393;
    .svg-container {
      background: #0C5393;
    }
  }
  ${theme.breakpoints.down("md")} {
    & {
      font-size: 12px;
    }
    & .svg-container {
      font-size: 14px;
      width: 18px;
      height: 18px;
      svg {
        height: 12px;
      }
    }
  }
  ${theme.breakpoints.down("sm")} {
    & {
      font-size: 14px;
    }
  }
  ${props =>
    props.secondary &&
      css`
      & {
        color: ${theme.palette.skyBlue};
      }
      & .svg-container {
        background: ${theme.palette.skyBlue};
      }
      &:hover {
        color: #0170D4;
        .svg-container {
          background: #0170D4;
        }
      }
  `}
  ${props =>
    props.stroke &&
      css`
      & {
        font-size: 14px;
        font-weight: 900;
        color: ${theme.palette.charcoal};
      }
      & .svg-container {
        width: 20px;
        height: 20px;
        background: none;
        position: relative;
        top: 2px;
        display: inline-block;
        vertical-align: middle;

        svg {
          height: 16px;
        }
      }
      &:hover {
        color: ${theme.palette.charcoal};
        .svg-container {
          background: none;
          path {
            stroke: ${theme.palette.skyBlue};
          }
        }
      }
  `}
  ${props =>
    props.white &&
      css`
      & {
        color: ${theme.palette.white};
      }
      & .svg-container {
        width: 20px;
        height: 20px;
        background: none;
        position: relative;
        top: 2px;
        display: inline-block;
        * {
          stroke: ${theme.palette.white};
        }
      }
      &:hover {
        color: ${theme.palette.charcoal};
        .svg-container {
          background: none;
          path {
            stroke: ${theme.palette.charcoal};
          }
        }
      }
  `}
  ${props =>
    props.save &&
      css`
      & .svg-container {
        * {
          stroke: ${theme.palette.white};
        }
      }
  `}
`;

const Span = styled.span`
  display: inline-block;
  vertical-align: middle;
`

export const Links = props => {
  const {children, svg, href, onClick} = props;
  const preventDefault = (event) => {
    event && event.preventDefault();
    onClick();
  };

  return (
    
    <LinksContainer
      {...props}
      href={href}
      onClick={(e) => (href === '#' && preventDefault(e))}>
        {svg && <ReactSVG src={svg} className="svg-container" /> }
        <Span className="svg-text-container test">
          {children}
        </Span>
    </LinksContainer>
  );
}

Links.propTypes = {
  href: PropTypes.string,
  svg: PropTypes.string,
  onClick: PropTypes.func,
  hasA: PropTypes.bool,
  regularLink: PropTypes.bool
};

Links.defaultProps = {
  href: '#',
  svg: chevronRight,
  onClick: () => {},
  hasA: false,
  regularLink: false
};

export default Links;