import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {theme} from '../../../themes/default-theme';
import Slider from "react-slick";
import Grid from '@material-ui/core/Grid';
import RespImg from '../RespImg';
import {ReactSVG} from 'react-svg'
import {Icon} from '..';
import NextLink from '../../navigation/NextLink';

import chevronLeft from '../../../assets/icons/chevron-left.svg';
import chevronRight from '../../../assets/icons/chevron-right.svg';

import shower from '../../../assets/icons/shower-head.svg';
import pin from '../../../assets/icons/pin-multi-coloured.svg';
import caravan from '../../../assets/icons/caravan.svg';

const TitleContainer = styled.div`
  font-size: 28px;
  text-align: center;
  font-weight: 500;
  color: ${theme.palette.charcoal};
  max-width: 780px;
  margin: 0 auto;
  padding-bottom: 46px;
  ${theme.breakpoints.down('sm')} {
    font-size: 16px;
    margin: 0 16px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    color: ${theme.palette.gunmentalGrey};
    ${theme.breakpoints.down('sm')} {
      font-size: 14px;
    }
  }
`

const SlickContainer = styled.div`
  .slick-list {
    padding-bottom: 30px;
  }
  ${theme.breakpoints.up('md')} {
    .six-slides .slick-slide {
      width: 33.33% !important;
    }
    .eight-slides .slick-slide {
      width: 25% !important;

      .grid-individual {
        padding: 10px;
      }
    }
  }
`

const GridIndividual = styled(props => {
  return <Grid {...props} />
})`
  text-align: center;
  display: inline-block;
  vertical-align: top;
  img {
    width: 100%;
  }
  ${theme.breakpoints.up('md')} {
    padding: 40px;
  }
`;

const CellTitle = styled.div`
  font-size: 20px;
  color: ${theme.palette.vividBlue};
  font-weight: 500;
  ${theme.breakpoints.down('sm')} {
    font-size: 14px;
  }
  &.black-cell-title {
    color: ${theme.palette.charcoal};
  }
`
const CellCaption = styled.div`
  font-size: 16px;
  color: ${theme.palette.gunmentalGrey};
  ${theme.breakpoints.down('sm')} {
    font-size: 14px;
  }
`

const PrevArrow = styled(props => {
  return (
    <Icon skyBlue {...props}>
      <ReactSVG src={chevronLeft} />
    </Icon>
  )}
)`
  position: absolute;
  bottom: 0;
  left: 45%;
  z-index: 99;
  ${theme.breakpoints.down('sm')} {
    left: 40%;
  }
  &.slick-disabled {
    opacity: .4;
  }
`

const NextArrow = styled(props => {
  return (
    <Icon skyBlue {...props}>
      <ReactSVG src={chevronRight} />
    </Icon>
  )}
)`
  position: absolute;
  bottom: 0px;
  right: 45%;
  z-index: 99;
  ${theme.breakpoints.down('sm')} {
    right: 40%;
  }
  &.slick-disabled {
    opacity: .4;
  }
`

const CellIcon = styled.div`
  width: 140px;
  height: 140px;
  text-align: center;
  display: inline-block;
  ${theme.breakpoints.down('sm')} {
    width: 34px;
    height: 34px;
  }
  &::after {
    content: '';
    background-size: cover !important;
    background-repeat: no-repeat !important;
    width: 100%;
    height: 100%;
    display: block;
  }
  &.shower-head::after {
    background: url('${shower}');
  }
  &.pin::after {
    background: url('${pin}');
  }
  &.caravan::after {
    background: url('${caravan}');
  }
`

export const ImageTextGrid = props => {
  const {data, settings} = props;

  var slickSettings = {
    responsive: [
      {
        breakpoint: theme.breakpoints.width('md'),
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true
        }
      }
    ],
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: settings.slidesToShow,
    slidesToScroll: 1,
    fade: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    ...settings
  };

  return (
    <div>
      <TitleContainer>
        {data.heading && data.heading}
        <p>{data.description && data.description}</p>
      </TitleContainer>
      <SlickContainer>
        <Slider
          {...slickSettings}
          className={settings.slidesToShow === 6 ? 'six-slides' : settings.slidesToShow === 8 ? 'eight-slides' : null}
        >
          {data.grid && data.grid.map((cell, index) => (
            <NextLink key={index} href={cell.link || '#'}>
              <a>
                <GridIndividual className="grid-individual">
                  {cell.image ? 
                    <RespImg 
                      {...cell.image}
                    />
                  : cell.icon ? 
                    <CellIcon
                      className={cell.icon + " icon-panel"}
                    />
                    : null
                  }
                  {cell.title && <CellTitle className={'black-cell-title'}>{cell.title}</CellTitle>}
                  {cell.description && <CellCaption>{cell.description}</CellCaption>}
                </GridIndividual>
              </a>
            </NextLink>
          ))}
        </Slider>
      </SlickContainer>
    </div>
  );
}

ImageTextGrid.propTypes = {
  data: PropTypes.object,
  settings: PropTypes.object
};

ImageTextGrid.defaultProps = {
  data: {},
  settings: {
    slidesToShow: 3
  }
}

export default ImageTextGrid;