import React from 'react';
import propTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import styled from 'styled-components';

import {theme} from '../../../themes/default-theme';

const Container = styled.div`
  display: inline-flex;
  justify-content: center;
  padding: 10px 0;
  border: 1px solid ${theme.palette.middleGrey};
  border-radius: 4px;
  flex-wrap: wrap;
  background-color: ${theme.palette.white};
  box-shadow: rgba(0, 0, 0, 0.15) 0px 12px 30px 0px;

  ${theme.breakpoints.up("md")} {
    &.model-page {
      position: absolute;
      display: flex;
      left: 16px;
    }
  }

  ${theme.breakpoints.down("sm")} {
    padding: 4px 0;
  }
`

const Item = styled.div`
  flex: 1 1 92px;
  text-align: center;
  padding: 10px;
  margin: 0 -1px;
  min-width: 92px;

  @media (min-width: 1000px) {
    flex: 1 1 120px;
    min-width: 120px;
  }

  &:not(:first-child) {
    position: relative;
    &:before {
      position: absolute;
      height: 35px;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      background-color: ${theme.palette.middleGrey};
      width: 1px;
      content: ' ';
      display: block;

      ${theme.breakpoints.up("md")} {
        height: 60px;
      }
    }
  }

  svg {
    height: 25px;
    width: auto;
    display: block;
    margin: 0 auto;

    ${theme.breakpoints.up("md")} {
      height: 40px;
    }
  }
`

const ItemText = styled.p`
  margin: 5px 0 0;
  font-size: 9px;
  text-align: center;

  ${theme.breakpoints.up("md")} {
    font-size: 14px;
  }
`
  
const IconKey = ({
  items,
  modelPage
}) => {
  return (
  	<Container className={modelPage ? 'model-page icon-key' : 'icon-key'}>
      {items.map((item, index) => (
        <Item key={item.id || index} className="icon-key__item">
          <ReactSVG src={item.svg} />
          <ItemText>{item.title}</ItemText>
        </Item>
      ))}
    </Container>
  )
}
  
IconKey.propTypes = {
  items: propTypes.array.isRequired,
  modelPage: propTypes.bool
}
  
IconKey.defaultProps = {
  items: []
}
  
export default IconKey