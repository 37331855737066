import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import styled from 'styled-components';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import { ReactSVG } from 'react-svg'

import {theme} from '../../../../src/themes/default-theme';
import Modal from '../Modal';
import Text from '../../typography';
import { RespImg, Icon} from '../../ui';
import Cta from '../../ui/Cta';
import chevronRight from '../../../../src/assets/icons/chevron-right.svg';


const useStyles = makeStyles(theme => ({
  modalOverlay: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  modalInner: {
    width: '100%',
    display: 'inline-block',
    position: 'relative',
    maxWidth: 800,
    paddingTop: 50
  },
  modalClose: {
    top: 0,
    left: 0,
    right: 0,
    marginBottom: '20px',
    position: 'absolute',

    [theme.breakpoints.up('md')]: {
      
    }
  }
}));

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 20px;
  background-color: ${theme.palette.lightGrey};
  width: 100%;
  overflow: scroll;

  ${theme.breakpoints.up('md')} {
    padding: 50px;
    overflow: auto;
  }

  ${theme.props.mobileNavDown} {
    padding-bottom: 115px;
  }
`

const Header = styled.div `
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  margin: auto;
  background: ${theme.palette.skyGradient};
  width: 220px;
  height: 40px;
  padding-top: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  top: 50px;

  ${theme.breakpoints.up('md')} {
    width: 334px;
    height: 60px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    padding-top: 15px;
    z-index: 7;
  }

  ${theme.breakpoints.down('sm')} {
    z-index: 99;
  }

  h6 {
    color: white;
    font-size: 20px;
    ${theme.breakpoints.down('sm')} {
      font-size: 13px;
      letter-spacing: 3px;
    }
  }
`

const Container = styled.div`

`

const Range = styled.div`
  margin: 50px 0;

  &:last-child {
    margin-bottom: 0;
  }
`

const RangeTitle = styled.div`
  margin-bottom: 9px;
`

const Model = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #D8D8D8;
  min-height: 77px;
  padding-right: 15px;
  padding-left: 5px;

  &:last-child {
     border-bottom: 0;
  }
`

const ModelContent = styled.div`
  display: flex;
  align-items: center;
`

const ModelImg = styled.div`
  display: inline-block;
  vertical-align: center;

  picture,
  img {
    width: 100%;
    height: auto;
    max-width: 100px;
    max-height: 66px;
    ${theme.breakpoints.down('sm')} {
      width: 80px;
    }
  }
`

const ModelTitle = styled.div`
  display: inline-block;
  vertical-align: center;
  padding-left: 25px;

  ${theme.breakpoints.down('sm')} {
    padding-left: 12px;
    padding-right: 12px;
  }
`

const ModelCta = styled.div`

`

const ModelSelectModal = ({
  title,
  ranges,
  onSelect,
  open,
  handleModal,
  width
}) => {
  // const [openModal, setOpenModal] = useState(open);

  // useEffect(() => {
  //   console.log('open', open);
  //   setOpenModal(open);
  // }, [open])
  
  const classes = useStyles();

  // console.log('modalse', openModal);

  return (
    <Modal
      isOpen={open}
      onClose={() => handleModal(false)}
      overlayClassname={classes.modalOverlay}
      innerClassname={classes.modalInner}
      closeClassname={classes.modalClose}
    >
      <Wrapper>
        <Header>
          <Text variant="h6" colour="white" align="center" uppercase>{title}</Text>
        </Header>
        <Container>
          {ranges.map(item => {
            return (
              <Range key={item.id}>
                <RangeTitle>
                  <Text variant="body1" align="left">{item.title}</Text>
                </RangeTitle>
                {item.models.map(model => {
                  return(
                    <Model key={model.id}>
                      <ModelContent>
                        <ModelImg>
                          {model.image &&
                            <>
                              {model.image.sizes ?
                                <RespImg 
                                  {...model.image}
                                />
                                :
                                <img src={model.image} alt={model.title} />
                              }
                            </>
                          }
                        </ModelImg>
                        <ModelTitle>
                          <Text variant="body1" colour="grey" align="left" uppercase>{model.title}</Text>
                        </ModelTitle>
                      </ModelContent>
                      <ModelCta>
                        {isWidthUp('md', width) ?
                          <Cta
                            inline 
                            outline={false}
                            onClick={() => onSelect(model)}
                          >
                            Select
                          </Cta>
                        :
                        <Icon onClick={() => onSelect(model)} vividBlue>
                          <ReactSVG src={chevronRight} />
                        </Icon>
                        }
                      </ModelCta>
                    </Model>
                  )
                })}
              </Range>
            )
          })}

        </Container>
      </Wrapper>
    </Modal>
  )
}
  
ModelSelectModal.propTypes = {
  title: propTypes.string,
  ranges: propTypes.array,
  onSelect: propTypes.func,
  open: propTypes.bool,
  handleModal: propTypes.func
}
  
ModelSelectModal.defaultProps = {
  title: 'Select a Model',
  ranges: [],
  onSelect: () => {},
  handleModal: () => {},
  open: false
}
  
export default withWidth()(ModelSelectModal)