import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import {Grid, Box} from '@material-ui/core';

import {Text} from '../../typography';
import {theme} from '../../../themes/default-theme';
import RespImg from '../RespImg';

const Container = styled.div`
  text-align: center;
`

const Icon = styled.div`
  width: 80px;
  margin: 0 auto 20px;

  ${theme.breakpoints.up('md')} {
    width: 98px;
  }

  ${theme.breakpoints.up('lg')} {
    width: 140px;
    margin: 0 auto 27px;
  }
`

const TextContainer = styled.div`
  max-width: 360px;
  margin: 0 auto;

  p:first-child {
    margin-top: 0;
  }
`
  
const IconText = ({
  icon,
  text
}) => {
  return (
  	<Container>
      <Icon>
        {icon &&
          <RespImg {...icon} type="png" />
        }
      </Icon>
      <TextContainer>
        <Grid container spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'flex-start'} >
          <Grid item xs={12}>
            <Box px={2}>
              <Text variant="body1" align="center">
                {ReactHtmlParser(text)}
              </Text>
            </Box >
          </Grid>
        </Grid>
      </TextContainer>
    </Container>
  )
}
  
IconText.propTypes = {
  icon: propTypes.object.isRequired,
  text: propTypes.string.isRequired
}
  
IconText.defaultProps = {
  icon: null,
  text: ''
}
  
export default IconText