import React from 'react';
import SwitchButton from '@material-ui/core/Switch';
import styled from 'styled-components';
import {theme} from '../../../themes/default-theme';

const Switch = styled(({ skyBlue, vividBlue, ...props }) => {
    return <SwitchButton {...props} />
})`
  & {
    width: 64px;
    height: 30px;
    padding: 1px;
    margin-left: 11px;
  }
  & .MuiSwitch-switchBase {
    width: 64px;
    height: 30px;
    color: white;
    transform: translateX(-18px);
    &:hover {
      background: none;
    }
  }
  & .Mui-checked {
    transform: translateX(18px);
    & + .MuiSwitch-track {
      background: ${props => props.vividBlue ? theme.palette.vividBlue : theme.palette.skyBlue};
      opacity: 1;
    }
  }
  & .MuiSwitch-thumb {
    width: 26px;
    height: 26px;
  }
  & .MuiSwitch-track {
    border-radius: 26px;
    border: 1px solid ${theme.palette.middleGrey};
    background: ${props => props.vividBlue ? theme.palette.lightGrey : theme.palette.white};
    opacity: 1;
  }
`;

export default Switch;