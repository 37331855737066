import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components';
import {ButtonBase} from '@material-ui/core';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import {theme} from '../../../themes/default-theme';
import Dropdown from '../../ui/Dropdown';

const Wrapper = styled.div`

`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  ${theme.breakpoints.down("sm")} {
    text-align: left;
  }
`

const Item = styled.div`
  padding: 4px 4px;
  width: 100%;

  ${props => !props.tabGap && `
    padding: 0;
    ${theme.breakpoints.up("md")} {
      &:first-child {
        & > * {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          border-left: 1px solid ${theme.palette.middleGrey};
        }
      }

      &:last-child {
        & > * {
          border-bottom-right-radius: 8px;
          border-top-right-radius: 8px;
        }
      }
    }
  `}

  ${props => props.maxWidth === 1 ? `

    ${theme.breakpoints.up("md")} {
      width: auto;
      flex-basis: 96px;
    }

    ${theme.breakpoints.up("lg")} {
      flex-basis: 106px;
      ${props => props.small === 1 && `
        max-width: 106px;
        flex: auto;
      `
    }
  ` : `
    ${theme.breakpoints.up("md")} {
      width: auto;
      button {
        padding: 0 15px;
      }
    }
  `}
`

const ItemContainer = styled(ButtonBase)`
  padding: 7px;
  transition: background-color 200ms ease-in-out;
  background-color: ${props => props.active == 1 ? theme.palette.vividBlue : 'white'};
  ${props => props.active === 1 && 'color:  white;'}
  font-size: 17px;
  height: 50px;
  display: block;
  width: 100%;
  white-space: nowrap;

  ${theme.breakpoints.up("md")} {
    ${props => props.border === 1 ? `
      ${props.active !== 1 && `
        border: 1px solid ${theme.palette.middleGrey};
        border-left: 0;
        padding: 6px 7px;
      `}
    ` : `
      border-top-left-radius: 17px;
    `}
  }
`

const TabsMobileDropdown = ({
  items,
  onChange,
  active,
  width,
  tabGap,
  maxWidth,
  small,
  neverNative
}) => {
  const activeItem = active || items && items[0].title;

  return (
  	<Wrapper>
      <Container>
        {isWidthUp('md', width) ?
          items.map(item => (
            <Item key={item.text} tabGap={tabGap} small={small ? 1 : 0} maxWidth={maxWidth ? 1 : 0}>
              <ItemContainer border={tabGap ? 0 : 1} onClick={() => onChange(item)} active={item.text === activeItem ? 1 : 0}>
                {item.text}
              </ItemContainer>
            </Item>
          ))
        : <Dropdown
            label={activeItem}
            options={items.map(item => {
              return {
                text: item.text,
                value: item.text
              }
            })}
            neverNative={neverNative}
            value={activeItem || undefined}
            defaultValue={activeItem}
            onChange={(e) => onChange(items.find(i => i.text === e.text))}
          />
        }
      </Container>
    </Wrapper>
  )
}
  
TabsMobileDropdown.propTypes = {
  items: propTypes.array.isRequired,
  active: propTypes.string,
  onChange: propTypes.func.isRequired,
  tabGap: propTypes.bool,
  small: propTypes.bool,
  maxWidth: propTypes.bool,
  neverNative: propTypes.bool,
}
  
TabsMobileDropdown.defaultProps = {
  items: [],
  active: null,
  onChange: () => {},
  tabGap: true,
  small: false,
  maxWidth: true,
  neverNative: false
}
  
export default withWidth()(TabsMobileDropdown)