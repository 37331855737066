import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {theme} from '../../themes/default-theme';
import {get} from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';

const TableContainerStyle =  styled(props => {
  return <TableContainer {...props} />
})`
  ${props => props.borderRadius && `
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  `}
`;

const Cell = styled(props => {
  return <TableCell {...props} />
})`
  width: 20%;
  ${props => props.category === 1 && `
    width: 40%;
  `}

  ${props => props.autoWidth && props.autoWidth === 1 && `
    width: auto !important;
  `}

  ${props => props.cellWidth !== null && `
    width: ${props.cellWidth} !important;
  `}

  ${props => props.grey === 1 && `
    background: ${theme.palette.lightGrey};
  `}

  ${props => props.highlight === 1 && `
    font-weight: bold;

    span {
      color: black;
    }
  `}
`;


const TableBodyStyle = styled(props => {
  return <TableBody {...props} />
})`
  .MuiTableCell-root {
    font-size: 16px;
    color: ${theme.palette.gunmentalGrey};

    ${theme.breakpoints.down('sm')} {
      font-size: 14px;
    }
  }
  .MuiTableCell-body {
    border: 1px solid #DCDCDC;
  }

  .MuiTableRow-root:nth-child(odd) {
    background: white;
  }

  td {
    width: 20%;
    ${theme.breakpoints.down('sm')} {
      text-align: center;
      width: 50%;
      word-break: break-word;
      font-size: 14px;
    }
  }

  td:first-child {
    ${theme.breakpoints.up('md')} {
      width: 40%;
      &.full-width {
        width: 100%;
      }
    }
  }

  tr:last-child td {
    border-bottom: 0;
  }
  tr td:first-child {
    border-left: 0;
  }
  tr td:last-child {
    border-right: 0;
  }

`;

export const TableOutput = props => {
  const {rows, highlight, compareItems} = props;

  return (
    <TableContainerStyle>
      <Table aria-label="Compare table">
        <TableBodyStyle>
          {rows && rows.map((row, index) => {
            return (
              <React.Fragment key={`${index}_${uuidv4()}`}>
                {!isWidthUp('md', props.width) &&
                  <TableRow>
                    <Cell category={1} autoWidth={props.cellWidth ? 1 : 0} className="full-width" colSpan="5" align="center">{row.category && row.category}</Cell>
                  </TableRow>
                }
                <TableRow>
                  {isWidthUp('md', props.width) &&
                    <Cell category={1} autoWidth={props.cellWidth ? 1 : 0}>{row.category && row.category}</Cell>
                  }
{/* 
                  {Object.keys(row.cols).map((col, index) => {
                    const tempItemsArr = [];
                    Object.keys(row.cols).forEach((_col, index) => {row.cols[_col] !== row.cols[col] && tempItemsArr.push(row.cols[col])})
                    const compare = tempItemsArr.indexOf(row.cols[col]);
                    return (
                      <Cell key={index} highlight={highlight && (compare !== -1) ? 1 : 0}><span>{row.cols[col]}</span></Cell>
                    )
                  })} */}

                  
                    {compareItems.map((item, index) => {
                      const val = get(item, 'model.value', null)
                      const tempItemsArr = [];
                      Object.keys(row.cols).forEach((_col, index) => {row.cols[_col] !== row.cols[val] && tempItemsArr.push(row.cols[val])})
                      const compare = tempItemsArr.indexOf(row.cols[val]);
                      if (row.cols[val]) {
                        return (
                          <Cell key={uuidv4()} cellWidth={props.cellWidth} highlight={highlight && (compare !== -1) ? 1 : 0}><span>{row.cols[val]}</span></Cell>
                        )
                      } 
                      return (
                      <Cell key={uuidv4()} cellWidth={props.cellWidth}><span>-</span></Cell>
                    )
                  })}

                  {props.showBlankCell && 
                    <Cell grey={1} cellWidth={props.cellWidth}>&nbsp;</Cell>
                  }
                  
                </TableRow>
              </React.Fragment>
            )
          })}
        </TableBodyStyle>
      </Table>
    </TableContainerStyle>
  );
}

TableOutput.propTypes = {
  row: PropTypes.array,
  highlight: PropTypes.bool,
  cellWidth: PropTypes.string,
  showBlankCell: PropTypes.bool
};

TableOutput.defaultProps = {
  row: [],
  false: true,
  cellWidth: null,
  showBlankCell: false
};

export default withWidth()(TableOutput);