import {get, sortBy} from 'lodash'
import produce from 'immer';

const getClosestCoordinate = (latLng, array, ) => {
  var closestDistance = 99999999999; // kms
  var closestItem;
  const lat1 = get(latLng, 'latitude', 0);
  const lon1 = get(latLng, 'longitude', 0);

  array.forEach(item => {
    const lat2 = get(item, 'latitude', 0);
    const lon2 = get(item, 'longitude', 0);
    const distance = getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2);
    if (distance < closestDistance) {
      closestItem = item
      closestDistance = distance
    }
  })

  return {item: closestItem, distance: closestDistance}
}

const getTopCoordinates = (latLng, array, noItems) => {
  var numberOfItems = noItems || 3

  const lat1 = get(latLng, 'latitude', 0);
  const lon1 = get(latLng, 'longitude', 0);

  const dealersWithDistance = array.map(item => {
    const lat2 = get(item, 'latitude', 0);
    const lon2 = get(item, 'longitude', 0);
    const distance = getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2);

    return{
      distance,
      ...item
    }
  })
  const sortedDealers = sortBy(dealersWithDistance, ['distance'], ['asc']);
  return sortedDealers.slice(0, numberOfItems)
}

const getDistanceFromLatLonInKm = (lat1,lon1,lat2,lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km
  return d;
}

const deg2rad = (deg) =>{
  return deg * (Math.PI/180)
}

export {getTopCoordinates, getClosestCoordinate as default}